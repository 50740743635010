import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconArrow from '../../../assets/icons/arrowCheckIns.svg'
import { trackEvent } from '../../../services/event-tracking'
import { useStores } from '../../../utils/stores'
import { AboutSama } from '../modals/aboutSama.modal'
import { Faq } from '../modals/faq.modal'

export const Spacer: React.FC = observer(() => {
    return <div className={classNames('h-[1px] p-0 bg-[#d2d9da] mt-4')}></div>
})

export const InfoSupport = observer(() => {
    const { t } = useTranslation()
    const { coachee, question } = useStores()
    const [isAboutOpen, setIsAboutOpen] = useState(false)
    const [faqOpen, setIsFaqOpen] = useState(false)

    useEffect(() => {
        trackEvent('screen_viewed', {
            name: 'info_support',
            coachee_id: coachee.coachee?._id,
        })
    }, [coachee.coachee?._id])

    useEffect(() => {
        const subscription = question
            .getFaq(coachee.coachee?.user?.appLanguage)
            .subscribe()

        return () => {
            subscription.unsubscribe()
        }
    }, [coachee.coachee?.user?.appLanguage, question])

    return (
        <div className="lg:pt-8 lg:px-12">
            <div>
                <span className="text-[38px] hidden lg:inline-block">
                    {t('account.infoSupport')}
                </span>
                <div className="hidden lg:block">
                    <Spacer />
                </div>

                <div className="space-y-2 mb-6 mt-2 flex flex-col">
                    <div
                        className="flex flex-row justify-between items-center lg:pt-3 lg:pb-2 pb-1 cursor-pointer"
                        onClick={() => setIsAboutOpen(true)}
                    >
                        <span className="text-lg mb-2">
                            {t('account.infoSupportPage.about')}
                        </span>
                        <img className="w-4 h-4" src={IconArrow} />
                    </div>

                    <Spacer />

                    <div
                        className="flex flex-row justify-between items-center lg:pt-3 lg:pb-2 pb-1 cursor-pointer"
                        onClick={() => setIsFaqOpen(true)}
                    >
                        <span className="text-lg mb-2">
                            {t('account.infoSupportPage.faq')}
                        </span>
                        <img className="w-4 h-4" src={IconArrow} />
                    </div>

                    <Spacer />

                    <a
                        href={`mailto:${t(
                            'account.infoSupportPage.contactUsLink',
                        )}`}
                        target="_blank"
                        className="block text-inherit no-underline"
                    >
                        <div className="flex flex-row justify-between items-center lg:pt-3 lg:pb-2 pb-1 cursor-pointer">
                            <span className="text-lg mb-2">
                                {t('account.infoSupportPage.contactUs')}
                            </span>
                            <img className="w-4 h-4" src={IconArrow} />
                        </div>
                    </a>

                    <Spacer />

                    <a
                        href={t('account.infoSupportPage.termsLink')}
                        target="_blank"
                        className="block text-inherit no-underline"
                    >
                        <div className="flex flex-row justify-between items-center lg:pt-3 lg:pb-2 pb-1 cursor-pointer">
                            <span className="text-lg mb-2">
                                {t('account.infoSupportPage.terms')}
                            </span>
                            <img className="w-4 h-4" src={IconArrow} />
                        </div>
                    </a>

                    <Spacer />

                    <a
                        href={t('account.infoSupportPage.privacyLink')}
                        target="_blank"
                        className="block text-inherit no-underline"
                    >
                        <div className="flex flex-row justify-between items-center lg:pt-3 lg:pb-2 pb-1 cursor-pointer">
                            <span className="text-lg mb-2">
                                {t('account.infoSupportPage.privacy')}
                            </span>

                            <img className="w-4 h-4" src={IconArrow} />
                        </div>
                    </a>

                    <Spacer />
                </div>
            </div>

            {isAboutOpen && (
                <AboutSama isOpen={isAboutOpen} setIsOpen={setIsAboutOpen} />
            )}

            {faqOpen && <Faq isOpen={faqOpen} setIsOpen={setIsFaqOpen} />}
        </div>
    )
})
