import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterModal } from '../../../components/modals'
import { useStores } from '../../../utils/stores'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const Faq: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()
    const { question } = useStores()

    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange =
        (panel: string) => (e: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }
    return (
        <CenterModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className="max-w-[36rem] pt-2 h-full overflow-y-scroll"
            headerTitle={t('account.infoSupportPage.faq')}
        >
            <div className="px-8 pt-4 mt-10 bg-[#fafafa]">
                {question.faq.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            borderTop:
                                expanded === `panel${index}` && index !== 0
                                    ? '1px solid #ccc'
                                    : 'none',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon sx={{ fontSize: '2rem' }} />
                            }
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <span
                                className={`text-lg  ${
                                    expanded === `panel${index}`
                                        ? 'font-bold'
                                        : 'py-4'
                                }`}
                            >
                                {item.question}
                            </span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <span
                                className="text-base"
                                style={{
                                    lineHeight: '1.1',
                                    whiteSpace: 'pre-wrap',
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </CenterModal>
    )
})
